import * as React from "react"
import { ImgDestra, ImgSinistra, ImgCentro, ImgLogo, Img, Citazione, IndiceLuoghi, PaginaTesto, ChiSono, ChiSiamo, A, FbPage } from "../../../components/componentiPagine"

export default function Render() {
	return <PaginaTesto lang="it" name="sicilia1860">
<h1>Tra le camicie rosse</h1>
<ImgCentro nome="messina.PNG" alt="Messina" didascalia="Messina"/>
<p>Nel 1859 Antonio viaggia instancabilmente per tutta l’Italia per cercare di seguire da più vicino possibile gli eventi cruciali della Penisola, tra cui i vari plebisciti che sanciscono l’unione dei Ducati dell’Italia centro-settentrionale al Regno di Sardegna.</p>
<p>Naturalmente dedica particolare attenzione al primo plebiscito per l’annessione dei territori dell’ex Ducato di Parma, Piacenza e Guastalla, sua terra natia.</p>
<p>L’11 maggio 1860 Antonio si trova a Londra quando giunge la notizia dello sbarco dei garibaldini a Marsala; il 16 agosto giunge a Palermo, ma, saputo che Garibaldi sta per imbarcarsi per Messina, lo raggiunge a bodo del vapore inglese “Amazon”.</p>
<p>Nelle settimane seguenti Antonio segue i movimenti dei garibaldini, che nel frattempo hanno attraversato lo Stretto di Messina e stanno risalendo la Calabria.</p>
<p>Dopo aver raggiunto la Basilicata, in settembre Antonio precede le camicie rosse a Salerno e cerca di seminare il panico nelle sfere nemiche diffondendo notizie false ed esagerate sul prossimo attacco alla città di Napoli.</p>
<p>A seguito dell’esperienza garibaldina, Antonio torna nella sua Castellamonte, nel cui seggio viene eletto durante le votazioni del 1860; l’anno seguente (1861) viene invece eletto nel collegio di Langhirano, presso Parma, altra sua città di origine.</p>
<p>Nel 1863 riprende invece la sua attività di corrispondente estero, e riceve l’incarico di recarsi negli Stati Uniti per seguire le vicende della Guerra di secessione: nonostante i Nordisti, in quanto cittadino inglese, gli neghino il permesso di recarsi al fronte, Antonio riesce a raggiungere l’Ohio e altri Stati del Midwest.<br />
Da questa prospettiva si rende conto che la guerra sta volgendo a favore degli Unionisti del Nord e, grazie alle sue abilità, riesca a rovesciare la politica del “Times” facendolo figurare dalla parte dei vincitori.</p>
<p>Negli anni seguenti segue per conto del celebre giornale inglese le vicende della guerra tra Danimarca e Prussia (1864), poi quelle della Spagna durante la crisi della monarchia borbonica e della successiva rivoluzione (1865-66, poi 1868-69).</p>
<p>Nel 1873 Antonio si spinge fino a Cuba: da questo viaggio nascerà la sua opera &quot;The Pearl of Antilles&quot; (1873).</p>

	</PaginaTesto>
}